@font-face {
    font-family: 'menlo';
    src: url("/public/Menlo-Regular.ttf");
}

@font-face {
    font-family: 'futura';
    src: url(/public/FuturaCyrillicBook.ttf);
}

@font-face {
    font-family: 'inter';
    src: url(/public/InterVariable.ttf);
}

h1, h2, h3, h4, h5, h6, body {
    color: white;
}

.menlo {
    font-family: menlo;
}

.inter {
    font-family: inter;
}

.futura {
    font-family: futura;
}

HTML CSSResult Skip Results Iframe EDIT ON

/* GLOBAL STYLES */
body {
    background: #333;
    padding-top: 5em;
    display: flex;
    justify-content: center;
}

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
    color: #fff;
    border-right: .15em solid;
    font-family: menlo;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation:
        typing 2.2s steps(16, end),
        blink-caret 1.2s step-end infinite;

}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.initial-fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 3.5s;
}

.fade-in-and-out {
    animation: fadeInAndOut 2s ease-in-out infinite;
    animation-delay: 3.5s;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to {
        border-color: transparent
    }

    50% {
        border-color: greenyellow
    }
}

.default-row-spacer {
    margin-bottom: 12px;
}

.default-spacer {
    margin-right: 12px;
}

.large-spacer {
    margin-right: 24px;
}

.very-large-spacer {
    margin-right: 48px;
}

.extremely-large-spacer {
    margin-right: 96px;
}

.large-row-spacer {
    margin-bottom: 24px;
}

.very-large-row-spacer {
    margin-bottom: 48px;
}

.extremely-large-row-spacer {
    margin-bottom: 96px;
}

.bold {
    font-weight: bold;
}

.semi-bold {
    font-weight: 600;
}

.slightly-bold {
    font-weight: 4--;
}

.small-margin {
    margin: 6px;
}

.normal-margin {
    margin: 12px;
}

.big-margin {
    margin: 24px;
}

.small-padding {
    padding: 6px;
}

.max-width-800 {
    max-width: 800px;
    width: 100%;
}

.max-width-500 {
    max-width: 500px;
    width: 100%;
}

.max-width-300 {
    max-width: 300px;
    width: 100%;
}

.min-width-500 {
    min-width: 500px;
}

.width-300 {
    width: 300px;
}

.hover {
    cursor: pointer;
}

.min-width-0 {
    min-width: 0;
}

.switch-to-vert {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1200px) {
    .switch-to-vert {
        flex-direction: column;
    }
}

small {
    color: white
}

p {
    color: white;

}

h6 {
    color: white;
}

.white-text {
    color: white;
}

.sticky-position {
    position: sticky;
}

@media (max-width: 1371px) {
    .hide-on-small {
        display: none !important;
    }
}

@media (min-width: 1372px) {
    .hide-on-big {
        display: none !important;
    }
}

.wrapFlex {
    display: flex;
    flex-wrap: wrap;
}



.container-fluid {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 20px;
    /* Adjust this value for desired left spacing */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
    width: 100vw;
    /* Ensure full viewport width */
}

.row {
    display: flex;
    width: 100%;
}

.fixed-column {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    flex-shrink: 0;
    flex-basis: 50%;
    /* Adjust based on your layout */
    padding-left: 20px;
    /* Additional padding if needed */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

.right-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-left: 20px;
    /* Adjust as needed for spacing */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

@media (max-width: 992px) {
    .container-fluid {
        /* padding-left: 0; */
        /* Remove padding for smaller screens if necessary */
        width: 100vw;
        /* Ensure full width on small screens */
    }

    .fixed-column {
        position: static;
        height: auto;
        overflow-y: visible;
        flex-basis: 100%;
        /* padding-left: 0; */
        /* Remove padding for smaller screens if necessary */
    }

    .right-column {
        padding-left: 0;
        /* Remove padding for smaller screens if necessary */
    }
}

.medium-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    /* This makes the image circular */
    object-fit: cover;
}